import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import { IRequestMethod, request } from '@mobro/request';
import { Iobject } from '@mobro/libs/es/type';
import { LOGIN_HOST } from '@/store/constants';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
];

export function getRouterConfig(): Promise<VueRouter> {
    return request({
        url: '/api/auth/menulist',
        method: IRequestMethod.GET,
        params: {},
        needGlobalTips: true,
        needAllData: true,
    })
        .then(res => {
            if (res.respCode === 10001) {
                window.location.replace(`${LOGIN_HOST}?redirect=${encodeURIComponent(location.href)}`);
            }
            return res.data;
        })
        .then(res => {
            const flatMenuList = (originMenuList: RouteConfig[]): RouteConfig[] => {
                const menuList = (originMenuList || [])
                    .map((menu: Iobject) => {
                        if (Array.isArray(originMenuList) && menu.children?.length > 0) {
                            return flatMenuList(menu.children);
                        } else if (!menu.path) {
                            return null;
                        }
                        return {
                            ...menu,
                            path: menu.path,
                            name: menu.name,
                            component: () => import(`../views${menu.componentPath}`),
                        };
                    })
                    .flat(2)
                    .filter(m => m) as RouteConfig[];
                return menuList;
            };

            const router = new VueRouter({
                mode: 'hash',
                base: process.env.BASE_URL,
                routes: routes.concat(flatMenuList(res)),
            });

            sessionStorage.setItem('menuList', JSON.stringify(res));

            return router;
        });
}
