import Vue from 'vue';
import VueInfiniteScroll from 'vue-infinite-scroll';
import {
    Aside,
    Button,
    Card,
    Checkbox,
    Col,
    Container,
    Descriptions,
    DescriptionsItem,
    Dialog,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Empty,
    Form,
    FormItem,
    Header,
    Image,
    Input,
    Link,
    Loading,
    Main,
    Menu,
    MenuItem,
    MenuItemGroup,
    Message,
    MessageBox,
    Notification,
    Option,
    Pagination,
    Popconfirm,
    Progress,
    Row,
    Select,
    Submenu,
    Switch,
    Table,
    TableColumn,
    Tag,
    Upload,
    Tooltip,
    Popover,
    InputNumber,
} from 'element-ui';

Vue.use(Button)
    .use(Container)
    .use(Aside)
    .use(Menu)
    .use(Submenu)
    .use(Dialog)
    .use(MenuItem)
    .use(MenuItemGroup)
    .use(Header)
    .use(Dropdown)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Main)
    .use(Link)
    .use(Input)
    .use(Row)
    .use(Col)
    .use(Form)
    .use(Switch)
    .use(FormItem)
    .use(Upload)
    .use(Progress)
    .use(Select)
    .use(Option)
    .use(Dialog)
    .use(Table)
    .use(TableColumn)
    .use(Pagination)
    .use(Descriptions)
    .use(DescriptionsItem)
    .use(Card)
    .use(Empty)
    .use(Image)
    .use(Tag)
    .use(Checkbox)
    .use(Link)
    .use(Popconfirm)
    .use(Tooltip)
    .use(Popover)
    .use(InputNumber);

Vue.use(Loading.directive);
Vue.use(VueInfiniteScroll);

Vue.config.productionTip = false;

// 全局设置组件样式
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };

// 全局弹窗样式注册
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
